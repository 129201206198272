import { graphql, Link, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import Hamburger from "../images/hamburger.svg";
import SearchHeader from "./search/searchHeader";
import { NavDropdown, Dropdown, DropdownButton } from "react-bootstrap";

const Header = ({ useLogoAsHeadline }) => {
  const { allCategories, lawPost } = useStaticQuery(graphql`
    query Categories {
      ...allCategories
      ...lawPost
    }
  `);

  const categories = allCategories.edges.map(
    (category) => category.node.frontmatter
  );

  const Logo = useLogoAsHeadline ? (
    <Link to="/">
      <h1 className="logo">
        <span>WEG</span> Wissen
      </h1>
    </Link>
  ) : (
    <Link to="/">
      <span className="logo">
        <span>WEG</span> Wissen
      </span>
    </Link>
  );

  return (
    <header className="main-header">
      <div className="logo-area">
        <Container className="py-3 d-flex justify-content-between">
          <div>{Logo}</div>
          <Link to="/hvv/">
            <span className="hausverwalter">
              <strong>NEU:</strong> Branchenverzeichnis für Hausverwaltungen
            </span>
          </Link>
        </Container>
      </div>
      <Navbar expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <img src={Hamburger} alt="Hamburger Menu" />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-0">
              <DropdownButton 
                title="Themen"
                id="category-dropdown"
                className="full-width-dropdown"
                renderMenuOnMount={true}
              >
                <div className="d-flex flex-wrap container">
                  {categories.length > 0 &&
                    categories.map((category) => (
                      <NavDropdown.Item
                        as={Link}
                        to={`/${category.slug}/`}
                        key={category.slug}
                        className="dropdown-item"
                      >
                        {category.title}
                      </NavDropdown.Item>
                    ))}
                </div>
              </DropdownButton>
            </Nav>
            <div className="d-flex flex-column flex-lg-row flex-nowrap me-md-auto">
              {lawPost && (
                <Link to={`/${lawPost.frontmatter.slug}/`} className="about">
                  WEG-Gesetz
                </Link>
              )}
              <Link to={`/legal-text-en/`} className="about">
                Legal Text (EN)
              </Link>
              <Link to={`/muster/`} className="about">
                Kostenlose Muster
              </Link>
              <Link to={`/ueber-uns/`} className="about">
                Über uns
              </Link>
            </div>
            <SearchHeader />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

Header.propTypes = {
  useLogoAsHeadline: PropTypes.bool,
};

Header.defaultProps = {
  useLogoAsHeadline: false,
};

export default Header;
