import React from 'react';
import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Link } from 'gatsby';

const Footer = () => {
	const [loading, setloading] = useState(true);
	const [showFormsOverlay, setShowFormsOverlay] = useState(false);

	useEffect(() => {
		setloading(false);
	}, []);

	useEffect(() => {
		if (!loading) {
			if (typeof window !== 'undefined') {
				const localStr = JSON.parse(
					window.localStorage.getItem('WEG_WISSEN_SURVEY_SHOW')
				);

				setShowFormsOverlay(localStr !== null ? localStr : true);
			}
		}
	}, [loading]);

	return (
		<>
			<footer className='main-footer mt-4 py-4'>
				<Container className='d-flex justify-content-between'>
						<small className='text-muted'>
							Copyright &copy; {new Date().getFullYear()} ganztags. GmbH
						</small>
						<div>
							<Link to='/haftungsausschluss/'>Haftungsausschluss</Link>
							<Link to='/impressum/'>Impressum</Link>
						</div>
				</Container>
			</footer>
			{showFormsOverlay !== false ? (
				<div
					id='forms-overlay'
					style={{ display: showFormsOverlay !== false ? 'table' : 'none' }}
				>
					<button
						id='forms-overlay-close'
						onClick={() => {
							setShowFormsOverlay(false);

							typeof window !== 'undefined' &&
								window.localStorage.setItem(
									'WEG_WISSEN_SURVEY_SHOW',
									JSON.stringify(false)
								);
						}}
					>
						x
					</button>
					<span id='forms-overlay-content'>
						Können Sie uns vier Fragen beantworten, um die Inhalte auf WEG
						Wissen weiter zu verbessern?
					</span>
					<Link
						id='forms-overlay-button'
						target='_blank'
						to='https://docs.google.com/forms/d/e/1FAIpQLSfZxmjissuJoa84iFU2vAuhz3kMb4gdf-O5udH95Qgidh50Fg/viewform'
					>
						Zur Umfrage →
					</Link>
				</div>
			) : null}
		</>
	);
};

export default Footer;
