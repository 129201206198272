/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import Footer from './footer';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/main.scss';
import CookieConsent from '../components/CookieConsent';

const Layout = ({ children, useLogoAsHeadline }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<>
			<CookieConsent cookieName='weg-wissen-gdpr-google-analytics'>
				Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren,
				Funktionen für soziale Medien anbieten zu können und die Zugriffe auf
				unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer
				Verwendung unserer Website an unsere Partner für soziale Medien, Werbung
				und Analysen weiter. Unsere Partner führen diese Informationen
				möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt
				haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben.
			</CookieConsent>
			<Header
				siteTitle={data.site.siteMetadata.title}
				useLogoAsHeadline={useLogoAsHeadline}
			/>
			<main className='py-4' style={{ minHeight: 'calc(100vh - 229px)' }}>
				{children}
			</main>
			<Footer />
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
	useLogoAsHeadline: false,
};

export default Layout;
