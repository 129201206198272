import React from "react";
import Layout from "./src/components/layout";

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>;
};

export const onRouteUpdate = ({ location, prevLocation }) => {
	// console.log('new pathname', location.pathname)
	// console.log('old pathname', prevLocation ? prevLocation.pathname : null)

	window.dataLayer = window.dataLayer || [];
	if (location.pathname !== (prevLocation ? prevLocation.pathname : null)) {
		window.dataLayer.push({
			event: 'pageview',
			page: location.pathname,
		});
	}
};
