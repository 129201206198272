import React, { useRef, useState } from "react";
import { navigate } from "gatsby";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const SearchHeader = () => {
  const [search, setSearch] = useState("");
  const [inputVisible, setInputVisible] = useState(false);
  const form = useRef(null);

  const handleSubmit = event => {
    event.preventDefault();
    navigate(`/search?keywords=${search}`);
  };

  const handleSearchClick = event => {
    event.preventDefault();
    if (!inputVisible) {
      setInputVisible(!inputVisible);
      return;
    }
    navigate(`/search?keywords=${search}`);
  };

  return (
    <Form
      inline={"true"}
      ref={form}
      onSubmit={e => handleSubmit(e)}
      className="align-items-center flex-lg-row mt-lg-0 d-flex"
    >
      <FormControl
        type="text"
        placeholder="Suchbegriff"
        className={`me-1 ${inputVisible ? "active" : null}`}
        onChange={event => setSearch(event.target.value)}
      />
      <Button type="submit" value="Suche" onClick={e => handleSearchClick(e)}>
        <FontAwesomeIcon
          icon={faSearch}
          style={{ width: "1rem", height: "1rem" }}
        />
      </Button>
    </Form>
  );
};

export default SearchHeader;
