exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-haftungsausschluss-js": () => import("./../../../src/pages/haftungsausschluss.js" /* webpackChunkName: "component---src-pages-haftungsausschluss-js" */),
  "component---src-pages-hausverwalter-js": () => import("./../../../src/pages/hausverwalter.js" /* webpackChunkName: "component---src-pages-hausverwalter-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-text-en-js": () => import("./../../../src/pages/legal-text-en.js" /* webpackChunkName: "component---src-pages-legal-text-en-js" */),
  "component---src-pages-muster-js": () => import("./../../../src/pages/muster.js" /* webpackChunkName: "component---src-pages-muster-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-law-js-content-file-path-content-law-index-mdx": () => import("./../../../src/templates/law.js?__contentFilePath=/home/runner/work/WEG-Wissen/WEG-Wissen/content/law/index.mdx" /* webpackChunkName: "component---src-templates-law-js-content-file-path-content-law-index-mdx" */)
}

